import { ChangeDetectorRef, Component, ElementRef, HostListener } from '@angular/core';

import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';
import { WebPageUrlValidationService } from '../services/web-page-url-validation.service';

@Component({
  selector: 'template-component-web-page',
  templateUrl: './web-page.component.html',
  styleUrls: ['./web-page.component.scss']
})
export class WebPageComponent {

  spinner = false;
  componentId;
  refresh;
  unload;
  zoom;
  interactive;
  scrollbars;
  src;
  validationResult;

  constructor(private elementRef: ElementRef,
    private componentsFactory: ComponentsService,
      private attributeDataFactory: AttributeDataService,
      private webPageUrlValidationService: WebPageUrlValidationService,
      private changeDetectorRef: ChangeDetectorRef) {
    componentsFactory.registerDirective({
      type: 'rise-web-page',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = this.componentsFactory.selected.id;
        this._load();
        this.srcChanged();
      }
    });
  }

  @HostListener('document:visibilitychange')
  onVisibilityChange() {
    if (this._directiveIsVisible() && !document.hidden) {
      this.srcChanged();
    }
  }

  _load() {
    const refresh = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'refresh');
    const unloadVal = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'unload');
    const zoom = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'zoom');
    const interactiveVal = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'interactive');
    const scrollbarsVal = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'scrollbars');

    this.src = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'src');
    this.unload = !unloadVal && unloadVal !== false ? true : unloadVal;
    this.zoom = zoom ? zoom.toString() : '100';
    this.interactive = !interactiveVal && interactiveVal !== false ? false : interactiveVal;
    this.scrollbars = !scrollbarsVal && scrollbarsVal !== false ? false : scrollbarsVal;
    this.refresh = refresh ? refresh.toString() : '0';
  }

  srcChanged() {
    if (this._validateSrcLocally()) {
      this.saveAttributeData();

      this.spinner = true;

      this.webPageUrlValidationService.validate(this.src)
        .then( (result) => {
          this.validationResult = result;
        })
        .finally( () => {
          this.spinner = false;
          this.changeDetectorRef.detectChanges();
        });
    }
    this.changeDetectorRef.detectChanges();
  }

  saveAttributeData() {
    this.attributeDataFactory.setAttributeData(this.componentId, 'src', this.src);
    this.attributeDataFactory.setAttributeData(this.componentId, 'refresh', parseInt(this.refresh, 10));
    this.attributeDataFactory.setAttributeData(this.componentId, 'unload', this.unload);
    this.attributeDataFactory.setAttributeData(this.componentId, 'zoom', parseInt(this.zoom, 10));

    if (!this.interactive || this.zoom > 100) {
      this.scrollbars = false;
    }

    this.attributeDataFactory.setAttributeData(this.componentId, 'interactive', this.interactive);
    this.attributeDataFactory.setAttributeData(this.componentId, 'scrollbars', this.scrollbars);
  }

  onAttributeChanged() {
    this.saveAttributeData();

    this.changeDetectorRef.detectChanges();
  }

  _isValidUrl(url) {
    let val;

    // Add https:// if no protocol parameter exists
    if (url.indexOf('://') === -1 || url.indexOf('://') > 5) {
      url = 'https://' + url;
    }

    try {
      val = new URL(url);
    } catch (error) {
      console.log(error);
      return false;
    }

    return val.protocol === "https:";
  }

  _directiveIsVisible() {
    // This directive is instantiated once by templateAttributeEditor
    // It becomes visible when <rise-web-page> is selected
    return this.componentsFactory.selected && (this.componentsFactory.selected.type === 'rise-web-page');
  }

  _validateSrcLocally() {
    //clear the error
    this.validationResult = '';

    let _src = !this.src ? '' : this.src.trim();
    if (_src === '') {
      //empty string is allowed
      return true;
    }

    //check if url is valid and secure
    if (this._isValidUrl(_src)) {
      return true;
    }

    this.validationResult = 'INVALID_URL';
    return false;
  }
}
