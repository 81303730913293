<div class="rise-data-table-container attribute-editor-component data-table-component">
  <div #scrollContainer class="templates-selector te-scrollable-container">
    <div (rvSortable)="sortItem($event)" appendTo=".component-container" class="sortable-list">
      @for (item of values; track item; let key = $index) {
        <div class="row templates-row rv-sortable-item row-hover">
          <div class="rv-sortable-handle">
            <streamline-icon class="streamline-component-icon" name="sortableHandle" width="14" height="14"></streamline-icon>
          </div>
          <div class="mr-auto w-100 mb-2">
            @for (field of fields; track field; let key = $index) {
              <div>
                <div class="flex-row mt-2">
                  <label class="data-table-label">{{ field.name }}:</label>
                  <input [shown]="field.type === 'text'" type="text" class="form-control" [(ngModel)]="item[key]" placeholder="Enter {{ field.name }}" (ngModelChange)="save()">
                  <textarea [shown]="field.type === 'multiline'" class="form-control resize-vertical" rows="2" [(ngModel)]="item[key]" placeholder="Enter {{ field.name }}" (ngModelChange)="save()"></textarea>
                  <div class="input-group w-100" style="margin-left: -2px" [shown]="field.type === 'image'">
                    <input type="text" class="form-control" [(ngModel)]="item[key]" placeholder="Enter URL" (ngModelChange)="save()">
                    <span class="input-group-addon u_clickable" (click)="selectFromStorage(item, key)" title="Select from Storage">
                      <streamline-icon name="riseStorage" width="16" height="16"></streamline-icon>
                    </span>
                  </div>
                </div>
                <div [ngClass]="{ 'has-error': invalidUrls.has(item[key])}">
                  <p [shown]="field.type === 'image'" class="help-block">
                    @switch (invalidUrls.get(item[key])) {
                      @case ('INVALID_PROTOCOL') {
                        <span>URL should start with "https://""</span>
                      }
                      @case ('INVALID_EXTENSION') {
                        <span>Image type is invalid. Valid types: JPG,JPEG,PNG,BMP,SVG,GIF,WEBP</span>
                      }
                    }
                  </p>
                </div>
              </div>
            }
          </div>
          <a href="#" (click)="removeItem(key)">
            <streamline-icon class="ml-3 streamline-component-icon" name="trash" width="16" height="18"></streamline-icon>
          </a>
        </div>
      }
    </div>
  </div>
  <div class="attribute-editor-component" >
    <div class="attribute-editor-row">
      <button id="te-data-table-add-row" class="btn btn-primary btn-block" (click)="addRow()">
        Add Row
      </button>
      <label for="te-data-table-upload-csv" class="btn btn-default btn-block">Import CSV</label>
      <a id="te-data-table-export" href="" (click)="exportCsv()" download="data.csv" class="btn btn-default btn-block">Export CSV</a>
      <input id="te-data-table-upload-csv" type="file" [hidden]="true" (change)="importCsv($event)" accept=".csv">
    </div>
  </div>
</div>
