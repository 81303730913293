<div class="flex-row  p-sm-5">
  <div class="flex-1 p-3 p-sm-4 p-md-5 mx-sm-5 my-sm-3 my-lg-5">
    <h1>Configure Bulk Activation</h1>
    <p>This page allows you to configure a USB flash drive for advanced activation
    of multiple Rise Vision Media Players.</p>
    <ol>
      <li>Enter the network information and time zone of your displays, and
        indicate whether you want to name them automatically or as you go. (You
        can update your display names at any time once they are added.)</li>
      <li>Generate the configuration file (risevision.ini) and save it to the root
        folder of your USB flash drive. Do not change the name of the file.</li>
      <li>Plug the USB flash drive into your media player, power it on, and setup
        will begin automatically.</li>
    </ol>
    <p><a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/articles/20745351943700">For more information and detailed instructions, click here.</a></p>
  </div>
  <div class="flex-1 border-container p-3 p-sm-4 p-md-5 mx-sm-5 my-sm-3 my-lg-5">
    <h4>Network & Location Information</h4>
    <div class="btn-group w-100">
      <button id="wifiSelector"
        class="btn w-50"
        [ngClass]="{'btn-toggle-blue-off': isEthernet, 'btn-toggle-blue-on': !isEthernet}"
        (click)="toggleEthernet()"
        title="WiFi">
        Wi-Fi
        <streamline-icon name="checkmark" [shown]="!isEthernet"></streamline-icon>
      </button>
      <button id="ethernetSelector"
        class="btn w-50"
        [ngClass]="{'btn-toggle-blue-off': !isEthernet, 'btn-toggle-blue-on': isEthernet}"
        (click)="toggleEthernet()"
        title="Ethernet">
        Ethernet
        <streamline-icon name="checkmark" [shown]="isEthernet"></streamline-icon>
      </button>
    </div>
    @if (!isEthernet) {
      <div class="mt-3 mb-3">
        <label for="ssid">Network Name (SSID)</label>
        <input class="form-control" name="ssid" [(ngModel)]="ssid" />
      </div>
      <div class="mt-3 mb-3">
        <label for="ssid">Wi-Fi Password</label>
        <input class="form-control" type="password" name="password" [(ngModel)]="password" />
      </div>
    }
    <div class="mt-3 mb-3">
      <label for="timezone">Time Zone</label>
      <select class="form-control" name="timezone" [(ngModel)]="timeZone">
        @for (tz of getTimezones(); track tz) {
          <option>{{tz}}</option>
        }
      </select>
    </div>
    <p>Would you like to configure your file to automatically add, activate, and
    name your displays?</p>
    <div class="madero-radio">
      <input type="radio" name="autoyesno" id="autoyes" [(ngModel)]="useClaimId" value="yes" />
      <label class="pl-3" for="autoyes">Yes, do everything automatically <i
        runZone matTooltipPosition="right" matTooltipShowDelay="0" matTooltipPositionAtOrigin="true" matTooltip="Displays will be named by the serial number of each device. You can rename your displays from the Rise Vision web application once you are finished." class="fa fa-question-circle"></i></label>
    </div>
    <div class="madero-radio">
      <input type="radio" name="autoyesno" [(ngModel)]="useClaimId" id="autono" value="no" />
      <label class="pl-3" for="autono">No, just set up the network and time zone, I will activate and name displays individually.</label>
    </div>
    <button (click)="generateConfigFile()" [disabled]="!isEthernet && !ssid"
      class="mt-3 btn btn-primary w-100">Generate Configuration File</button>
  </div>
</div>
