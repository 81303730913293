import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserStateService } from 'src/app/auth/services/user-state.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PowerBIService {
  public static readonly ENDPOINT_GROUPS = 'groups';
  public static readonly ENDPOINT_DASHBOARDS = 'dashboards';
  public static readonly ENDPOINT_SIGNATURE = 'signature';

  constructor(
    protected httpClient: HttpClient,
    private userStateService: UserStateService
  ) { }

  _getRequestOptions(): any {
    var authorization = (this.userStateService.getAccessToken().token_type === 'Bearer') ?
      this.userStateService.getAccessToken().token_type + ' ' + this.userStateService.getAccessToken().access_token :
      this.userStateService.getAccessToken().access_token;

    var headers = new HttpHeaders({
      'authorization': authorization
    });

    var requestOptions = {
      headers,
      withCredentials: true,
      responseType: 'json'
    };

    return requestOptions;
  }

  getGroups(account) {
    let options = this._getRequestOptions();

    options.params = new HttpParams({fromObject:{
      account: account
    }});

    const request = new HttpRequest( 'GET',
      environment.POWERBI_SERVICE_URL + PowerBIService.ENDPOINT_GROUPS, options
    );

    return this.httpClient.request(request)
      .toPromise()
      .then((response: any) => {
        if (response && response.body && response.body.groups) {
          return response.body.groups;
        } else {
          throw new Error('Invalid powerbi groups data');
        }
      }).catch((err) => {
        console.error('Failed to get powerbi groups.', err);

        throw err;
      });
  }

  getDashboards(account, groupId) {
    let options = this._getRequestOptions();

    options.params = new HttpParams({fromObject:{
      account: account,
      groupId: groupId === 'my-workspace' ? '' : groupId
    }});

    const request = new HttpRequest( 'GET',
      environment.POWERBI_SERVICE_URL + PowerBIService.ENDPOINT_DASHBOARDS, options
    );

    return this.httpClient.request(request)
      .toPromise()
      .then((response: any) => {
        if (response && response.body && response.body.dashboards) {
          return response.body.dashboards;
        } else {
          throw new Error('Invalid powerbi dashboards data');
        }
      }).catch((err) => {
        console.error('Failed to get powerbi dashboards.', err);

        throw err;
      });
  }

  getSignature(account) {
    let options = this._getRequestOptions();

    options.params = new HttpParams({fromObject:{
      account: account,
      provider: 'ms-pbi'
    }});

    const request = new HttpRequest( 'GET',
      environment.DATA_SERVICE_URL + PowerBIService.ENDPOINT_SIGNATURE, options
    );

    return this.httpClient.request(request)
      .toPromise()
      .then((response: any) => {
        if (response && response.body && response.body.signature) {
          return response.body.signature;
        } else {
          throw new Error('Invalid signature data');
        }
      }).catch((err) => {
        console.error('Failed to get signature.', err);

        throw err;
      });
  }

}
