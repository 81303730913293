import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';

import { UIRouterModule } from '@uirouter/angular';
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';
import { CommonHeaderModule } from '../common-header/common-header.module';
import { routes } from './editor.routes';

import { EditorService } from './services/editor.service';
import { WidgetUtilsService } from './services/widget-utils.service';

import { PresentationListComponent } from './components/presentation-list/presentation-list.component';
import { FooterComponent } from './components/footer/footer.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { ArtboardComponent } from './components/artboard/artboard.component';
import { ArtboardPresentationComponent } from './components/artboard-presentation/artboard-presentation.component';
import { ArtboardPlaceholderComponent } from './components/artboard-placeholder/artboard-placeholder.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { WorkspaceComponent } from './components/workspace/workspace.component';
import { WorkspaceElementDirective } from './directives/workspace-element.directive';
import { PlaceholderDragDirective } from './directives/placeholder-drag.directive';
import { PlaceholderResizeDirective } from './directives/placeholder-resize.directive';
import { PlaceholdersListComponent } from './components/placeholders-list/placeholders-list.component';
import { PlaceholderPlaylistComponent } from './components/placeholder-playlist/placeholder-playlist.component';
import { PlaceholderSettingsComponent } from './components/placeholder-settings/placeholder-settings.component';
import { ResolutionSelectorComponent } from './components/resolution-selector/resolution-selector.component';
import { PresentationPropertiesComponent } from './components/presentation-properties/presentation-properties.component';
import { PlaylistItemComponent } from './components/playlist-item/playlist-item.component';
import { PlaceholderNameDirective } from './directives/placeholder-name.directive';
import { WidgetItemComponent } from './components/widget-item/widget-item.component';
import { WidgetSettingsComponent } from './components/widget-settings/widget-settings.component';
import { PresentationItemComponent } from './components/presentation-item/presentation-item.component';
import { PresentationSelectorComponent } from './components/presentation-selector/presentation-selector.component';
import { PresentationMultiSelectorComponent } from './components/presentation-multi-selector/presentation-multi-selector.component';
import { AddPresentationComponent } from './components/add-presentation/add-presentation.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { WeeklyTemplatesComponent } from './components/weekly-templates/weekly-templates.component';
import { GadgetService } from './services/gadget.service';
import { StoreContentComponent } from './components/store-content/store-content.component';
import { UsernamePipe } from '../shared/pipes/username.pipe';
import { EmbeddingPresentationsModalComponent } from './components/embedding-presentations-modal/embedding-presentations-modal.component';
import { ViewportComponent } from './viewport/viewport.component';
import { TemplateCalendarComponent } from './components/template-calendar/template-calendar.component';
import { PresentationNameDirective } from './directives/presentation-name.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild(routes),
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    CodemirrorModule,
    ComponentsModule,
    SharedModule,
    CommonHeaderModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatChipsModule
  ],
  declarations: [
    PresentationListComponent,
    FooterComponent,
    ToolbarComponent,
    HtmlEditorComponent,
    ArtboardComponent,
    ArtboardPresentationComponent,
    ArtboardPlaceholderComponent,
    SidebarComponent,
    WorkspaceComponent,
    WorkspaceElementDirective,
    PlaceholderDragDirective,
    PlaceholderResizeDirective,
    PlaceholdersListComponent,
    PlaceholderPlaylistComponent,
    PlaceholderSettingsComponent,
    ResolutionSelectorComponent,
    PresentationPropertiesComponent,
    PlaylistItemComponent,
    PlaceholderNameDirective,
    WidgetItemComponent,
    WidgetSettingsComponent,
    PresentationItemComponent,
    PresentationSelectorComponent,
    PresentationMultiSelectorComponent,
    AddPresentationComponent,
    ProductDetailsComponent,
    StoreContentComponent,
    WeeklyTemplatesComponent,
    EmbeddingPresentationsModalComponent,
    ViewportComponent,
    TemplateCalendarComponent,
    PresentationNameDirective,
  ],
  exports: [
    PresentationNameDirective
  ],
  providers: [
    UsernamePipe
  ]
})
export class EditorModule {
  //workaround for including downgraded components into build files
  //https://github.com/angular/angular/issues/35314#issuecomment-584821399

  static entryComponents = [ ];
  static providers = [ EditorService, WidgetUtilsService, GadgetService ];
}
