import { Component, ElementRef } from '@angular/core';
import { ComponentsService } from '../../services/components.service';
import { PlaylistService } from '../services/playlist.service';
import { UserComponentsService } from '../services/user-components.service';

@Component({
  selector: 'component-playlist-item-selector',
  templateUrl: './playlist-item-selector.component.html',
  styleUrls: ['./playlist-item-selector.component.scss']
})
export class PlaylistItemSelectorComponent {

  isUserComponents: boolean = false;
  playlistComponents: any[] = [];

  constructor(
    public playlistService: PlaylistService,
    public userComponentsService: UserComponentsService,
    private componentsService: ComponentsService,
    private elementRef: ElementRef
  ) {
    componentsService.registerDirective({
      type: 'rise-playlist-item-selector',
      element: this.elementRef.nativeElement,
      show: (options?) => {
        this.playlistComponents = options?.userComponents ? this.userComponentsService.playlistComponents : this.playlistService.playlistComponents;
        this.isUserComponents = options?.userComponents;
      },
      getLabel: id => {
        return this.isUserComponents ? 'Select a component' : null;
      }
    });
  }

  addItem(type: string, options?) {
    const addItemFunc = this.isUserComponents ? this.userComponentsService.addItem.bind(this.userComponentsService) : this.playlistService.addItem.bind(this.playlistService);

    this.componentsService.showPreviousPage();
    addItemFunc(type, options);
  }

  getPlaylistComponents() {
    return this.isUserComponents ? this.userComponentsService.playlistComponents : this.playlistService.playlistComponents;
  }

}
