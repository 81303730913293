import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'madero-checkbox',
  templateUrl: './madero-checkbox.component.html',
  styleUrls: ['./madero-checkbox.component.scss']
})
export class MaderoCheckboxComponent {

  @HostListener('click')
  onClick() {
    if (this.ngModel !== undefined && !this.disabled) {
      this.ngModel = !this.ngModel;
      this.ngModelChange.emit(this.ngModel);
    }
  }

  private _ngModel: any;
  @Input() set ngModel(value: any) {
    this._ngModel = !!value;
  }
  get ngModel(): any {
    return this._ngModel;
  }
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  @Input() disabled?: boolean;
  @Input() ngValue?: any;
  @Input() checkboxId!: string;

  constructor() { }

}
