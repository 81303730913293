
<div [hidden]="display.useCompanyAddress" class="list-group-item address-fields">
  <div class="form-group">
    <label class="control-label">Description:</label>
    <input type="text" class="form-control" [(ngModel)]="display.addressDescription">
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label class="control-label">Street:</label>
        <input type="text" class="form-control" [(ngModel)]="display.street" name="street">
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="control-label">Unit:</label>
        <input type="text" class="form-control" [(ngModel)]="display.unit" name="unit">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label class="control-label">City:</label>
        <input type="text" class="form-control" [(ngModel)]="display.city" name="city">
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="control-label">Country:</label>
        <select id="displayAddressCountrySelect" class="form-control" [(ngModel)]="display.country" name="country" (change)="detectChanges()">
          <option [value]="undefined" hidden>&lt; Select Country &gt;</option>
          @for (c of countries; track c) {
            <option [ngValue]="c.code">{{ c.name }}</option>
          }
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label class="control-label">State/Province/Region:</label>
        @if (display.country !== 'US' && display.country !== 'CA') {
          <input type="text" class="form-control" [(ngModel)]="display.province" name="province" />
        }
        @else if (display.country === 'CA') {
          <select class="form-control selectpicker" [(ngModel)]="display.province" name="province">
            <option [value]="undefined" hidden>&lt; Select Province &gt;</option>
            @for (r of regionsCA; track r) {
              <option [ngValue]="r[1]">{{ r[0] }}</option>
            }
          </select>
        }
        @else if (display.country === 'US') {
          <select class="form-control selectpicker" [(ngModel)]="display.province" name="province">
            <option [value]="undefined" hidden>&lt; Select State &gt;</option>
            @for (r of regionsUS; track r) {
              <option [ngValue]="r[1]">{{ r[0] }}</option>
            }
          </select>
        }
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="control-label">Zip / Postal Code:</label>
        <input type="text" class="form-control" [(ngModel)]="display.postalCode" name="postalCode">
      </div>
    </div>
  </div>
</div><!--display address-->
