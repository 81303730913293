import { Injectable } from '@angular/core';

import { pick } from 'lodash';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { CoreAPILoader } from 'src/app/ajs-upgraded-providers';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Injectable({
  providedIn: 'root'
})
export class PresentationApiService {

  static readonly PRESENTAION_WRITABLE_FIELDS = [
    'name', 'layout', 'distribution', 'isTemplate', 'embeddedIds', 'productCode',
    'presentationType', 'templateAttributeData'
  ];

  static readonly PRESENTAION_SEARCH_FIELDS = [
    'name', 'id', 'revisionStatusName'
  ];

  constructor(
    private coreAPILoader: CoreAPILoader,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService
  ) { }

  _createSearchQuery (fields, search) {
    var query = '';

    for (var i in fields) {
      query += 'OR ' + fields[i] + ':~\"' + search + '\" ';
    }

    query = query.substring(3);

    return query.trim();
  }

  list (search, cursor?) {
    var query = search.query ?
      this._createSearchQuery(PresentationApiService.PRESENTAION_SEARCH_FIELDS, search.query) : '';
    query += search.filter ? ((search.query ? ' AND' : '') + search.filter) : '';

    var obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'search': query,
      'cursor': cursor,
      'count': search.count,
      'sort': search.sortBy + (search.reverse ? ' desc' : ' asc')
    };
    console.debug('list presentations called with', obj);
    return this.coreAPILoader()
      .then(coreApi => {
        return coreApi.presentation.list(obj);
      })
      .then(resp => {
        console.debug('list presentations resp', resp);

        return resp.result;
      })
      .catch(e => {
        console.error('Failed to get list of presentations.', e);
        return Promise.reject(e);
      });
  }

  get (presentationId) {
    var obj = {
      'id': presentationId,
      'companyId': this.companyStateService.getSelectedCompanyId()
    };

    console.debug('get presentation called with', presentationId);
    return this.coreAPILoader()
      .then(coreApi => {
        return coreApi.presentation.get(obj);
      })
      .then(resp => {
        console.debug('get presentation resp', resp);
        return resp.result;
      })
      .catch(e => {
        console.error('Failed to get presentation.', e);
        return Promise.reject(e);
      });
  }

  add (presentation) {
    var fields = pick.apply(this, [presentation].concat(
      PresentationApiService.PRESENTAION_WRITABLE_FIELDS));
    var obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'data': fields
    };

    return Promise.resolve()
      .then(() => {
        return this.coreAPILoader();
      })
      .then(coreApi => {
        return coreApi.presentation.add(obj);
      })
      .then(resp => {
        console.debug('added presentation', resp);
        return resp.result;
      })
      .catch(e => {
        console.error('Failed to add presentation.', e);
        throw e;
      });
  }

  update (presentationId, presentation) {
    var fields = pick.apply(this, [presentation].concat(
      PresentationApiService.PRESENTAION_WRITABLE_FIELDS));
    if (this.userStateService.hasRole('ba')) {
      fields.isStoreProduct = presentation.isTemplate && presentation
        .isStoreProduct;
    }
    var obj = {
      'id': presentationId,
      'data': fields
    };

    console.debug('update presentation called with', presentationId);
    return Promise.resolve()
      .then(() => {
        return this.coreAPILoader();
      })
      .then(coreApi => {
        return coreApi.presentation.patch(obj);
      })
      .then(resp => {
        console.debug('update presentation resp', resp);
        return resp.result;
      })
      .catch(e => {
        console.error('Failed to update presentation.', e);
        throw e;
      });
  }

  delete (presentationId, forceDelete?) {
    var obj = {
      'id': presentationId,
      'force': forceDelete
    };

    console.debug('delete presentation called with', presentationId);
    return this.coreAPILoader().then(coreApi => {
        return coreApi.presentation.delete(obj);
      })
      .then(resp => {
        console.debug('delete presentation resp', resp);
        return resp;
      })
      .catch(e => {
        console.error('Failed to delete presentation.', e);
        return Promise.reject(e);
      });
  }

  copy (presentationId, companyId) {
    var obj = {
      'id': presentationId,
      'companyId': companyId
    };

    console.debug('copy presentation called with', presentationId);
    return this.coreAPILoader()
      .then(coreApi => {
        return coreApi.presentation.copy(obj);
      })
      .then(resp => {
        console.debug('copy presentation resp', resp);
        return resp;
      })
      .catch(e => {
        console.error('Failed to copy presentation.', e);
        return Promise.reject(e);
      });
  }

  publish (presentationId) {
    var obj = {
      'id': presentationId
    };

    console.debug('publish presentation called with', presentationId);
    return this.coreAPILoader()
      .then(coreApi => {
        return coreApi.presentation.publish(obj);
      })
      .then(resp => {
        console.debug('publish presentation resp', resp);
        return resp;
      })
      .catch(e => {
        console.error('Failed to publish presentation.', e);
        return Promise.reject(e);
      });
  }

  restore (presentationId) {
    var obj = {
      'id': presentationId
    };

    console.debug('restore presentation called with', presentationId);
    return this.coreAPILoader()
      .then(coreApi => {
        return coreApi.presentation.restore(obj);
      })
      .then(resp => {
        console.debug('restore presentation resp', resp);
        return resp.result;
      })
      .catch(e => {
        console.error('Failed to restore presentation.', e);
        return Promise.reject(e);
      });
  }

  listEmbeddingPresentations (presentationId) {
    var obj = {
      'id': presentationId
    };

    console.debug('list embedding presentations called with', presentationId);
    return this.coreAPILoader()
      .then(coreApi => {
        return coreApi.presentation.listEmbeddingPresentations(obj);
      })
      .then(resp => {
        console.debug('list embedding presentations resp', resp);
        return resp.result;
      })
      .catch(e => {
        console.error('Failed to list embedding presentations.', e);
        return Promise.reject(e);
      });
  }

  listEmbeddingSchedules (presentationId) {
    var obj = {
      'id': presentationId
    };

    console.debug('list embedding schedules called with', presentationId);
    return this.coreAPILoader()
      .then(coreApi => {
        return coreApi.presentation.listEmbeddingSchedules(obj);
      })
      .then(resp => {
        console.debug('list embedding schedules resp', resp);
        return resp.result;
      })
      .catch(e => {
        console.error('Failed to list embedding schedules.', e);
        return Promise.reject(e);
      });
  }

}

angular.module('risevision.apps.services')
  .factory('presentation', downgradeInjectable(PresentationApiService));