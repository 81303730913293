import { Injectable } from '@angular/core';
import { StorageUtilsService } from './storage-utils.service';
import { StorageFileTypePipe } from '../pipes/storage-file-type.pipe';

import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  storageFull: boolean = true;
  selectorType: string = '';
  selectorFilter: { name: string, extensions: string[] } = StorageUtilsService.SELECTOR_FILTERS.ALL;

  constructor (
    private storageUtilsService: StorageUtilsService,
    private storageFileTypePipe: StorageFileTypePipe
  ) { }

  reset () {
    this.storageFull = true;
    this.selectorType = '';
    this.selectorFilter = StorageUtilsService.SELECTOR_FILTERS.ALL;
  }

  private _isFilteredFile (file) {
    if (this.storageUtilsService.fileIsFolder(file)) {
      return false;
    }

    let filtered = false;

    if (this.selectorFilter.extensions) {
      filtered = !this._fileHasExtension(file, this.selectorFilter.extensions);
    }

    return filtered;
  }

  private _fileHasExtension (file, allowedExtensions) {
    const fileType = this.storageFileTypePipe.transform(file.name);
    return allowedExtensions.indexOf(fileType.toLowerCase()) !== -1;
  }

  setSelectorType (type, filter) {
    this.selectorType = StorageUtilsService.SELECTOR_TYPES.SINGLE_FILE;
    this.selectorFilter = StorageUtilsService.SELECTOR_FILTERS.ALL;

    for (let j in StorageUtilsService.SELECTOR_TYPES) {
      if (type === StorageUtilsService.SELECTOR_TYPES[j]) {
        this.selectorType = StorageUtilsService.SELECTOR_TYPES[j];

        break;
      }
    }

    for (let k in StorageUtilsService.SELECTOR_FILTERS) {
      if (filter === StorageUtilsService.SELECTOR_FILTERS[k].name) {
        this.selectorFilter = StorageUtilsService.SELECTOR_FILTERS[k];

        break;
      }
    }
  }

  isMultipleSelector () {
    return this.storageFull ||
      this.selectorType === StorageUtilsService.SELECTOR_TYPES.MULTIPLE_FILE ||
      this.selectorType === StorageUtilsService.SELECTOR_TYPES.MULTIPLE_FILES_FOLDERS;
  }

  isFileSelector () {
    return this.storageFull ||
      this.selectorType === StorageUtilsService.SELECTOR_TYPES.SINGLE_FILE ||
      this.selectorType === StorageUtilsService.SELECTOR_TYPES.MULTIPLE_FILE ||
      this.selectorType === StorageUtilsService.SELECTOR_TYPES.MULTIPLE_FILES_FOLDERS;
  }

  isFolderSelector () {
    return this.storageFull ||
      this.selectorType === StorageUtilsService.SELECTOR_TYPES.SINGLE_FOLDER ||
      this.selectorType === StorageUtilsService.SELECTOR_TYPES.MULTIPLE_FILES_FOLDERS;
  }

  isMultipleFilesFoldersSelector () {
    return this.selectorType === StorageUtilsService.SELECTOR_TYPES.MULTIPLE_FILES_FOLDERS;
  }

  isFolderFilter () {
    return this.selectorFilter === StorageUtilsService.SELECTOR_FILTERS.FOLDERS;
  }

  canSelect (file) {
    return !this.storageUtilsService.fileIsTrash(file) && !file.isThrottled &&
      (this.storageUtilsService.fileIsFolder(file) && this.isFolderSelector() ||
        !this.storageUtilsService.fileIsFolder(file) && this.isFileSelector()
      ) &&
      !this._isFilteredFile(file);
  }

  isDisabled (file) {
    return this._isFilteredFile(file) ||
      !this.storageUtilsService.fileIsFolder(file) && !this.isFileSelector();
  }

  fileIsImage (file) {
    return this._fileHasExtension(file, StorageUtilsService.SELECTOR_FILTERS.IMAGES.extensions);
  }

  fileIsVideo (file) {
    return this._fileHasExtension(file, StorageUtilsService.SELECTOR_FILTERS.VIDEOS.extensions);
  }

}

angular.module('risevision.storage.services')
  .factory('storageFactory', downgradeInjectable(StorageService));
