import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { FileMetadataUtilsService } from './file-metadata-utils.service';
import { StorageAPILoader } from 'src/app/ajs-upgraded-providers';
import { LogoImageService } from './logo-image.service';
import { StorageUtilsService } from 'src/app/storage/services/storage-utils.service';

@Injectable({
  providedIn: 'root'
})
export class FileExistenceCheckService {

  constructor(
    private storageAPILoader: StorageAPILoader,
    private storageUtils: StorageUtilsService,
    private fileMetadataUtilsService: FileMetadataUtilsService
  ) { }


  _requestFileData(companyId, file) {
    const search = {
      'companyId': companyId,
      'file': file
    };

    return this.storageAPILoader()
      .then((storageApi) => {
        return storageApi.files.get(search);
      });
  }

  _getThumbnailDataFor(fileName, defaultThumbnailUrl) {
    const invalidThumbnailData = {
      exists: false,
      timeCreated: '',
      url: ''
    };
    const match = this.storageUtils.parseFilePath(fileName);

    if (!match) {
      return Promise.resolve(invalidThumbnailData);
    } else if (this.storageUtils.isDefaultFileOnTestAppsEnvironment(fileName)) {
      return Promise.resolve({
        exists: true,
        timeCreated: '',
        url: defaultThumbnailUrl
      });
    }

    return this._requestFileData(match[1], match[2])
      .then( (resp) => {
        var file = resp && resp.result && resp.result.result &&
          resp.result.files && resp.result.files[0];

        if (!file) {
          return invalidThumbnailData;
        }

        var url = this.fileMetadataUtilsService.thumbnailFor(file, defaultThumbnailUrl);

        return {
          exists: !!url,
          timeCreated: this.fileMetadataUtilsService.timeCreatedFor(file),
          url: url
        };
      })
      .catch( (error) => {
        console.error(error);

        return invalidThumbnailData;
      });
  }

  _loadMetadata(fileNames, defaultThumbnailUrl) {
    const promises = _.map(fileNames, (fileName) => {
      return this._getThumbnailDataFor(fileName, defaultThumbnailUrl)
        .then( (data) => {
          return {
            file: fileName,
            exists: data.exists,
            'time-created': data.timeCreated,
            'thumbnail-url': data.url
          };
        })
        .catch( (error) => {
          console.error(error);
        });
    });

    return Promise.all(promises).then((results) => {
      const metadata = [];

      _.reject(results, _.isNil).forEach( (file) => {
        metadata.push(file);
      });

      return metadata;
    });
  }

  requestMetadataFor(files: string|string[], defaultThumbnailUrl: string): Promise<any> {
    const fileNames = this.fileMetadataUtilsService.filesAttributeToArray(files);

    return this._loadMetadata(fileNames, defaultThumbnailUrl);
  }

  checkFileExists(path: string): Promise<boolean> {
    return this.requestMetadataFor([path], LogoImageService.DEFAULT_IMAGE_THUMBNAIL).then((metadata) => {
      return !!metadata[0].exists;
    });
  }
}
