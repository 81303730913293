import { ChangeDetectorRef, Component, ElementRef, HostListener } from '@angular/core';

import { environment } from 'src/environments/environment';

import { AnalyticsFactory } from 'src/app/ajs-upgraded-providers';
import { ModalService } from 'src/app/components/modals/modal.service';

import { AttributeDataService } from '../../services/attribute-data.service';
import { GoogleCalendarService } from '../services/google-calendar.service';
import { ComponentsService } from '../../services/components.service';
import { GoogleApiOAuthService } from 'src/app/components/content-oauth/services/google-api-oauth.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

const GOOGLE_CALENDAR_SCOPE = 'https://www.googleapis.com/auth/calendar.readonly';

@Component({
  selector: 'template-component-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {

  public componentId: string;
  public source: string;
  public selectedCalendar: string;
  public selectedName: string;
  public range: number;
  public period: string;
  public completed: boolean;

  public rangeMin: number;
  public rangeMax: number;

  public spinner: boolean;
  public revokeFailed: boolean;
  public sourceResult: string;
  public userAccount: string;
  public componentAccount: string;
  public calendars = [];

  get sameAccount(): boolean {
    return !!this.userAccount && this.userAccount === this.componentAccount;
  }

  constructor(
    private modalService: ModalService,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private analyticsFactory: AnalyticsFactory,
    private attributeDataService: AttributeDataService,
    private componentsFactory: ComponentsService,
    private templateEditorService: TemplateEditorService,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    private googleCalendarService: GoogleCalendarService,
    private googleApiOAuthService: GoogleApiOAuthService) {

    this.range = 1;
    this.period = 'weeks';
    this.completed = true;
    this.selectedCalendar = '';
    this.selectedName = '';
    this.calendars = [];

    this.updateValidRange();

    this.spinner = false;
    this.revokeFailed = false;
    this.sourceResult = '';
    this.userAccount = null;
    this.componentAccount = null;

    this.componentsFactory.registerDirective({
      type: 'rise-data-calendar',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = componentsFactory.selected.id;
        this.load();
      }
    });
  }

  @HostListener('document:visibilitychange')
  onVisibilityChange() {
    if (this._directiveIsVisible() && !document.hidden) {
      this.sourceChanged();
    }
  }

  load() {
    this.source = this.attributeDataService.getAvailableAttributeData(this.componentId, 'source');
    this.range = this.attributeDataService.getAvailableAttributeData(this.componentId, 'range') || 1;
    this.period = this.attributeDataService.getAvailableAttributeData(this.componentId, 'period');
    this.completed = this.attributeDataService.getAvailableAttributeData(this.componentId, 'completed');
    this.componentAccount = this.attributeDataService.getAvailableAttributeData(this.componentId, 'account');

    if (this.componentAccount) {
      this.selectedCalendar = this.source;
      this.selectedName = this.attributeDataService.getAvailableAttributeData(this.componentId, 'name');
    } else {
      this.selectedCalendar = '';
    }

    this._validateGoogleCredentials();

    this.updateValidRange();
  }

  selectedCalendarChanged() {
    // if selected calendar is not public URL, then save selected calendarId
    if (!this._isPublicUrl()) {
      const calendarItem = this.calendars.find(calendar => calendar.id === this.selectedCalendar);

      this.source = this.selectedCalendar;
      this.selectedName = calendarItem.name;
      this.saveAttributeData();
    } else {
      this.source = "";
      this.selectedName = "";
      this.sourceChanged();
    }

    this.changeDetectorRef.detectChanges();
  }

  sourceChanged() {
    if (!this._isPublicUrl()) {
      return;
    }

    let oldSource = this.attributeDataService.getAvailableAttributeData(this.componentId, 'source');

    // Reset validation to avoid showing the success icon on invalid input
    this.sourceResult = '';
    this.spinner = true;

    return this.googleCalendarService.validate(this.source)
      .then((result) => {
        this.sourceResult = result;

        if (result === 'VALID' && oldSource !== this.source) {
          this.analyticsFactory.track('Calendar Component Updated', {
            companyId: this.companyStateService.getSelectedCompanyId(),
            presentationId: this.templateEditorService.presentation.id,
            presentationName: this.templateEditorService.presentation.name,
            componentId: this.componentId
          });

          this.saveAttributeData();
        }
      })
      .finally(() => {
        this.spinner = false;

        this.changeDetectorRef.detectChanges();
      });
  }

  periodChanged() {
    this.updateValidRange();

    if (this.isValidRange()) {
      this.saveAttributeData();
    }

    this.changeDetectorRef.detectChanges();
  }

  rangeChanged() {
    if (this.isValidRange()) {
      this.saveAttributeData();
    }

    this.changeDetectorRef.detectChanges();
  }

  completedChanged() {
    this.saveAttributeData();

    this.changeDetectorRef.detectChanges();
  }

  isValidRange() {
    return this.range >= this.rangeMin && this.range <= this.rangeMax;
  }

  calendarLink() {
    let calendarId = this.googleCalendarService.extractCalendarId(this.source);

    return `https://calendar.google.com/calendar/embed?src=${calendarId}`;
  }

  private saveAttributeData() {
    this.attributeDataService.setAttributeData(this.componentId, 'environment', environment.production ? 'prod' : 'test');
    this.attributeDataService.setAttributeData(this.componentId, 'provider', 'google');

    if (this.sourceResult === 'VALID' || !this._isPublicUrl()) {
      this.attributeDataService.setAttributeData(this.componentId, 'source', this.source);
    }

    if (this.isValidRange()) {
      this.attributeDataService.setAttributeData(this.componentId, 'range', this.range);
      this.attributeDataService.setAttributeData(this.componentId, 'period', this.period);
    }

    this.attributeDataService.setAttributeData(this.componentId, 'completed', this.completed);

    if (this._isPublicUrl()) {
      this.attributeDataService.setAttributeData(this.componentId, 'account', null);
      this.attributeDataService.setAttributeData(this.componentId, 'name', '');
    } else {
      this.attributeDataService.setAttributeData(this.componentId, 'account', this.sameAccount ? this.userAccount : this.componentAccount);
      this.attributeDataService.setAttributeData(this.componentId, 'name', this.selectedName);
    }

  }

  saveAccount(account) {
    this.componentAccount = account;
    this.saveAttributeData();
  }

  private updateValidRange() {
    this.rangeMin = 1;

    if (this.period === 'days') {
      this.rangeMax = 7;
    } else if (this.period === 'weeks') {
      this.rangeMax = 4;
    } else if (this.period === 'months') {
      this.rangeMax = 12;
    } else {
      this.period = 'weeks';
      this.updateValidRange();
    }
  }

  private _directiveIsVisible() {
    // This directive is instantiated once by templateAttributeEditor
    // It becomes visible when <rise-data-calendar> is selected
    return this.componentsFactory.selected && (this.componentsFactory.selected.type === 'rise-data-calendar');
  }

  private _validateGoogleCredentials() {
    this.spinner = true;

    return this.googleApiOAuthService.getConnectionStatus(GOOGLE_CALENDAR_SCOPE)
      .then(() => {
        this.userAccount = this.googleApiOAuthService.getUserIdentifier();

        if (!this.componentAccount) {
          this.saveAccount(this.userAccount);
        }

        return this._populateCalendarList();
      })
      .catch(() => {
        console.log('Calendar Account not connected');

        return this._populateCalendarList();
      })
      .finally(() => {
        this.spinner = false;

        this.changeDetectorRef.detectChanges();
      });
  }

  private _populateCalendarList() {
    this.calendars = [];

    if (this.sameAccount) {
      return this.googleCalendarService.getCalendars(this.userAccount)
      .then((result : any) => {
        this.calendars = result;
      });
    }

    if (this.componentAccount) {
      this.calendars = [{id: this.source, name: this.selectedName}];
    }
  }

  private _isPublicUrl() {
    return !this.selectedCalendar;
  }

  _connectAccount() {
    this.spinner = true;

    return this.googleApiOAuthService.authenticate(GOOGLE_CALENDAR_SCOPE)
      .then(() => {
        this._resetAccount(false);

        this.userAccount = this.googleApiOAuthService.getUserIdentifier();

        this.saveAccount(this.userAccount);

        return this._populateCalendarList();
      })
      .catch((err) => {
        console.log('Failed to connect', err);
      })
      .finally(() => {
        this.spinner = false;
      });
  }

  confirmConnect() {
    this.modalService.confirm('Connect to Google Calendar', 'Allow Rise Vision to View your Google Calendars and Calendar Events')
    .then(() => {
      this._connectAccount();
    })
    .catch(() => {});
  }

  _resetSource() {
    //reset if not public URL
    if (!this._isPublicUrl()) {
      this.selectedCalendar = '';
      this.selectedName = '';
      this.source = '';
      this.sourceChanged();
    }
  }

  _changeAccount() {
    this._resetAccount(false);

    this.userAccount = this.googleApiOAuthService.getUserIdentifier();

    this.saveAccount(this.userAccount);

    this._populateCalendarList();
  }

  _revokeAccount() {
    return this.googleCalendarService.revoke(this.googleApiOAuthService.getUserIdentifier())
      .then((revoked: boolean) => {
        this.userAccount = null;

        if (!revoked) {
          console.log('Token could not be revoked');

          this.revokeFailed = true;
        } else if (!this.userStateService.isRiseAuthUser()) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log('Failed to revoke account', err.message);
      });
  }

  _resetAccount(revoke) {
    this.calendars = [];

    this._resetSource();

    this.userAccount = null;
    this.selectedCalendar = '';
    this.selectedName = '';
    this.saveAccount(null);

    this.spinner = true;

    if (!revoke) {
      return;
    }

    this.templateEditorService.hasUnsavedChanges = true;

    this.templateEditorService.save()
      .then(() => {
        return this._revokeAccount();
      })
      .catch((err) => {
        console.log('Failed to save presentation', err.message);
      })
      .finally(() => {
        this.spinner = false;
      });
  }

  confirmChange() {
    this.modalService.confirm('Connect to Google Calendar', 'Allow Rise Vision to View your Google Calendars and Calendar Events')
    .then(() => {
      this._changeAccount();
    })
    .catch(() => {});
  }

  confirmDisconnect() {
    let disconnectMessage = 'Any content that is using this Google Calendar account will stop working.';

    if (!this.userStateService.isRiseAuthUser()) {
      disconnectMessage += ' If this account is the same as the one you use to Sign In to Rise Vision,'
        + ' it will also log you out of the application. You will be prompted to Sign In again if that happens.';
    }

    this.modalService.confirm('Disconnect from Google Calendar', disconnectMessage)
      .then(() => {
        this._resetAccount(true);
      })
      .catch(() => {});
  }
}
