import { Component, Input, Output, EventEmitter } from '@angular/core';

import { TemplateEditorUtilsService } from '../../services/template-editor-utils.service';

@Component({
  selector: 'template-editor-file-entry',
  templateUrl: './file-entry.component.html',
  styleUrls: ['./file-entry.component.scss']
})
export class FileEntryComponent {

  @Input() fileType: string;
  @Input() entry: any = {};
  @Output()
  public removeEvent = new EventEmitter<any>();
  @Input() showGripHandle: boolean;

  private STREAMLINE_URI = /^streamline:(.+)/;
  private DEFAULT_URI = 'https://s3.amazonaws.com/Rise-Images/UI/';

  constructor(private templateEditorUtils: TemplateEditorUtilsService) { }

  getFileName() {
    return this.entry ? this.templateEditorUtils.fileNameOf(this.entry.file) : '';
  }

  getStoragePath() {
    return this.entry ? this.templateEditorUtils.parseStoragePath(this.entry.file) : '';
  }

  getThumbnailUrl() {
    return this.entry && this.entry['thumbnail-url'];
  }

  isStreamlineThumbnail() {
    const thumbnailUrl = this.getThumbnailUrl();

    return !!(thumbnailUrl && this.STREAMLINE_URI.test(thumbnailUrl));
  }

  isDefaultThumbnail() {
    const thumbnailUrl = this.getThumbnailUrl();

    return !!(thumbnailUrl && thumbnailUrl.startsWith(this.DEFAULT_URI));
  }

  getStreamlineIcon() {
    return this.isStreamlineThumbnail() ?
      this.getThumbnailUrl().match(this.STREAMLINE_URI)[1] : '';
  }

  removeFileFromList() {
    this.removeEvent.emit(this.entry);
  }

}
