import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';
import { DisplayService } from '../../services/display.service';
import { PlayerProService } from 'src/app/displays/services/player-pro.service';
import { PlayerActionsService } from 'src/app/displays/services/player-actions.service';
import { PlayerLicenseService } from 'src/app/displays/services/player-license.service';
import { DisplayControlService } from 'src/app/displays/services/display-control.service';
import { PresentationUtilsService } from 'src/app/editor/services/presentation-utils.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { environment } from 'src/environments/environment';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'display-fields',
  templateUrl: './display-fields.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  styleUrls: ['./display-fields.component.scss']
})
export class DisplayFieldsComponent implements OnInit {

  @Input() selectedSchedule: any = {};
  @Output() selectedScheduleChange: EventEmitter<any> = new EventEmitter<any>();

  isMobile = this.presentationUtilsService.isMobileBrowser();
  isMonitoringAvailable = this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_MONITORING);
  isDisplayControlAvailable = this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_DISPLAY_CONTROL);
  isClassroomAlertsAvailable = this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_CLASSROOM_ALERTS);
  showWebPlayer = false;
  timePickerKey = false;
  displayForm: NgForm;

  constructor(
    public userStateService: UserStateService,
    public companyStateService: CompanyStateService,
    public playerActionsService: PlayerActionsService,
    public playerLicenseService: PlayerLicenseService,
    public playerProService: PlayerProService,
    public displayService: DisplayService,
    private displayControlService: DisplayControlService,
    private presentationUtilsService: PresentationUtilsService,
    private featuresService: FeaturesService,
    private modalService: ModalService,
    broadcasterService: BroadcasterService,
    private trackerService: TrackerService,
    private controlContainer: ControlContainer
  ) {
    broadcasterService.on('risevision.company.updated', () => {
      const company = companyStateService.getCopyOfSelectedCompany(true);
      displayService.display.playerProAuthorized = displayService.display.playerProAuthorized ||
        company.playerProAvailableLicenseCount > 0 && displayService.display.playerProAssigned;
    });
  }

  ngOnInit(): void {
    this.displayForm = <NgForm>this.controlContainer;
  }

  scheduleSelected () {
    this.selectedScheduleChange.emit(this.selectedSchedule);

    this.displayService.confirmAssignLicense(this.selectedSchedule);
  }

  confirmLicensing () {
    return this.modalService.confirm('Assign license?',
        'Do you want to assign one of your licenses to this display?',
        'Yes', 'No')
      .then(() => {
        // Toggle license as if they clicked the checkbox
        this.displayService.display.playerProAuthorized = true;

        this.displayService.toggleProAuthorized();
      });
  }

  confirmDelete () {
    this.modalService.confirmDanger('Delete Display?',
        'Are you sure you want to delete this Display?',
        'Yes', 'No')
      .then(() => { return this.displayService.deleteDisplay(); });
  }

  getWebPlayerUrl () {
    if (!this.displayService.display) {
      return '';
    }
    return environment.VIEWER_URL + '/?type=webplayer&id=' + this.displayService.display.id;
  }

  copyToClipboard (text) {
    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(text);
    }
  }

  openTimePicker ($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  toggleDisplayControl() {
    this.displayControlService.toggleDisplayControl();
  }

  trackDisplayMonitoring () {
    this.trackerService.displayEvent('Display Monitoring Updated',
      this.displayService.display.id,
      this.displayService.display.name,
      {
        monitoringEnabled: this.displayService.display.monitoringEnabled
      }
    );
  }

  promptUpgrade (feature) {
    this.featuresService.showUpgradePlanModal(feature);
  }

}
