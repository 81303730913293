import { Component, OnDestroy } from '@angular/core';

import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';

import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';

@Component({
  selector: 'plan-banner',
  templateUrl: './plan-banner.component.html',
  styleUrls: ['./plan-banner.component.scss']
})
export class PlanBannerComponent implements OnDestroy {

  subscription;

  constructor(private broadcaster: BroadcasterService,
    private currentPlanFactory: CurrentPlanService) {

    this.subscription = this.broadcaster.on('risevision.plan.loaded', () => {
      this.plan = this.currentPlanFactory.currentPlan;
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  plan: any = {};
  showPlans = this.currentPlanFactory.showPurchaseOptions.bind(this.currentPlanFactory);

  isEnterpriseSubCompany = this.currentPlanFactory.isEnterpriseSubCompany.bind(this.currentPlanFactory);

  getVisibleBanner() {
    var banner = 'free';

    if (this.currentPlanFactory.isParentPlan()) {
      banner = 'parent';
    } else if (this.currentPlanFactory.currentPlan.isPurchasedByParent) {
      banner = 'parentPurchased';
    } else if (this.currentPlanFactory.isCancelledActive()) {
      banner = 'cancelled';
    } else if (this.currentPlanFactory.isFree() && this.currentPlanFactory.isCancelled()) {
      banner = 'free';
    } else if (this.currentPlanFactory.isSubscribed()) {
      banner = 'subscribed';
    } else if (this.currentPlanFactory.isOnTrial()) {
      banner = 'trial';
    } else if (this.currentPlanFactory.isTrialExpired()) {
      banner = 'expired';
    } else if (this.currentPlanFactory.isSuspended()) {
      banner = 'suspended';
    }

    return banner;
  }

}

angular.module('risevision.common.components')
  .directive(
    'planBanner',
    downgradeComponent({
      component: PlanBannerComponent
    }) as angular.IDirectiveFactory
  );
