import { Injectable } from '@angular/core';
import { pick } from 'lodash';

import { RiseAPILoader } from 'src/app/ajs-upgraded-providers';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService {

  static readonly USER_PROFILE_WRITABLE_FIELDS = [
    'firstName',
    'lastName',
    'companyRole',
    'settings'
  ];
  static readonly USER_TERMS_WRITABLE_FIELDS = [
    'firstName',
    'lastName'
  ];

  static readonly COMPANY_PROFILE_WRITABLE_FIELDS = [
    'name',
    'companyIndustry',
    'country',
    'province'
  ];

  constructor(private riseAPILoader: RiseAPILoader) { }

  agreeToTerms(username, profile) {
    console.debug('agreeToTerms called.', username, profile);

    return this.riseAPILoader().then((riseApi) => {
      const fields = pick.apply(this, [profile].concat(
        AccountApiService.USER_TERMS_WRITABLE_FIELDS));

      return riseApi.account.agreeToTerms({
        data: fields
      });
    })
    .then((resp) => {
      console.debug('agreeToTerms resp', resp);

      return resp;
    })
    .catch((err) => {
      console.error('agreeToTerms failed', err);

      throw err;
    });
  }

  addAccount(profile, company) {
    console.debug('addAccount called.');

    return this.riseAPILoader().then((riseApi) => {
      const userFields = pick.apply(this, [profile].concat(
        AccountApiService.USER_PROFILE_WRITABLE_FIELDS));
      const companyFields = pick.apply(this, [company].concat(
        AccountApiService.COMPANY_PROFILE_WRITABLE_FIELDS));

      const fields = Object.assign({}, userFields, companyFields);

      return riseApi.account.add({
        data: fields
      });
    })
    .then((resp) => {
      console.debug('addAccount resp', resp);

      return resp;
    })
    .catch((err) => {
      console.error('addAccount failed', err);

      throw err;
    });
  }

  getAccount() {
    console.debug('getAccount called.');

    return this.riseAPILoader().then((riseApi) => {
      return riseApi.account.get();
    })
    .then((resp) => {
      console.debug('getAccount resp', resp);

      return resp.result.item;
    })
    .catch((err) => {
      console.error('getAccount failed', err);

      throw err;
    });
  }

}