<section ngModelGroup="addressForm" #addressForm="ngModelGroup" provinceValidator>
  @if (hideCompanyName !== true) {
    <div class="form-group" [ngClass]="{ 'has-error': isFieldInvalid('companyName') }">
      <label for="address-form-companyName" class="control-label">Company Name: *</label>
      <input id="address-form-companyName" name="companyName" type="text" class="form-control" [(ngModel)]="addressObject.name" autocomplete="organization" aria-required="true" tabindex="1" required>
      <p class="text-danger" [shown]="isFieldInvalid('companyName')">
        Choose a Company Name.
      </p>
    </div>
  }
  <div class="row">
    <div class="col-md-6">
      <div class="form-group" [ngClass]="{ 'has-error': isFieldInvalid('street') }">
        <label for="address-form-streetAddress" class="control-label">Street Address: *</label>
        <input #street="ngModel" id="address-form-streetAddress" name="street" type="text" class="form-control" [(ngModel)]="addressObject.street" autocomplete="address-line1" pattern=".{0,50}" aria-required="true" tabindex="1" required>
        <p class="text-danger" [shown]="isFieldInvalid('street')">
          Choose a Street Address.
        </p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="address-form-unit" class="control-label">Unit:</label>
        <input id="address-form-unit" type="text" name="unit" class="form-control" [(ngModel)]="addressObject.unit" autocomplete="address-line2" aria-required="true" tabindex="1" pattern=".{0,100}">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group" [ngClass]="{ 'has-error': isFieldInvalid('city') }">
        <label for="address-form-city" class="control-label">City: *</label>
        <input id="address-form-city" name="city" type="text" class="form-control" [(ngModel)]="addressObject.city" autocomplete="address-level2" aria-required="true" tabindex="1" required>
        <p class="text-danger" [shown]="isFieldInvalid('city')">
          Choose a City.
        </p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group" [ngClass]="{ 'has-error': isFieldInvalid('country') }">
        <label for="address-form-country" class="control-label">Country: *</label>
        <select #country="ngModel" id="address-form-country" name="country" autocomplete="country" class="form-control has-placeholder"
          [(ngModel)]="addressObject.country" (change)="detectChanges()" aria-required="true" tabindex="1" required>
          <option [value]="undefined" hidden>Choose your country ...</option>
          @for (c of countries; track c) {
            <option value="{{c.code}}">{{c.name}}</option>
          }
        </select>
        <p class="text-danger" [shown]="isFieldInvalid('country')">
          Choose a Country.
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group" [ngClass]="{ 'has-error': isProvinceInvalid() }">
        <label class="control-label">State/Province/Region: <span [hidden]="addressObject.country !== 'US' && addressObject.country !== 'CA'">*</span></label>
        <input #province="ngModel" name="province" type="text" class="form-control" [(ngModel)]="addressObject.province" (change)="detectChanges()" autocomplete="address-level1" placeholder="Enter your state/province/region ..."
          [shown]="addressObject.country !== 'US' && addressObject.country !== 'CA'" tabindex="1">
        <select #provinceSelector="ngModel" name="provinceSelector" class="form-control has-placeholder" [(ngModel)]="addressObject.province" (change)="detectChanges()" autocomplete="address-level1" [shown]="addressObject.country === 'CA'" tabindex="1">
          <option [value]="undefined" hidden>Choose your province ...</option>
          @for (c of regionsCA; track c) {
            <option value="{{c[1]}}">{{c[0]}}</option>
          }
        </select>
        <select #stateSelector="ngModel" name="stateSelector" class="form-control has-placeholder" [(ngModel)]="addressObject.province" (change)="detectChanges()" autocomplete="address-level1" [shown]="addressObject.country === 'US'" tabindex="1">
          <option [value]="undefined" hidden>Choose your state ...</option>
          @for (c of regionsUS; track c) {
            <option value="{{c[1]}}">{{c[0]}}</option>
          }
        </select>
        <p class="text-danger" [shown]="isProvinceInvalid() && addressObject.country === 'CA'">
          Choose a Province.
        </p>
        <p class="text-danger" [shown]="isProvinceInvalid() && addressObject.country === 'US'">
          Choose a State.
        </p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group" [ngClass]="{ 'has-error': isFieldInvalid('postalCode') }">
        <label for="address-form-postalCode" class="control-label">ZIP/Postal Code: *</label>
        <input #postalCode="ngModel" id="address-form-postalCode" name="postalCode" type="text" class="form-control" [(ngModel)]="addressObject.postalCode" autocomplete="postal-code" pattern=".{0,11}" aria-required="true" tabindex="1" required>
        <p class="text-danger" [shown]="isFieldInvalid('postalCode')">
          Choose a ZIP/Postal Code.
        </p>
      </div>
    </div>
  </div>
</section>
