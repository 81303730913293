<div class="image-component-container" [ngClass]="{ 'rise-admin': userStateService.isRiseAdmin() }">
  @if (helpText) {
    <div class="attribute-editor-component">
      <div class="text-sm attribute-editor-row">
        {{helpText}}
      </div>
    </div>
  }
  <div class="image-component-list-duration"
    [shown]="selectedImages.length > 1 && !isUploading">
    <label>Show each image for:</label>
    <div class="row">
      <div class="col-xs-12">
        <div class="input-group">
          <input type="number" class="form-control" [(ngModel)]="values.duration" (change)="saveDuration()">
          <span class="input-group-addon">Seconds</span>
        </div>
      </div>
    </div>
    @if (false) {
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="control-label u_margin-sm-top">Transition:</label>
            <select class="form-control" [(ngModel)]="values.transition" (change)="saveTransition()">
              <option value="">No Transition</option>
              <option value="fadeIn">Fade In</option>
              <option value="slideRight">Slide Right</option>
              <option value="slideLeft">Slide Left</option>
              <option value="slideUp">Slide Up</option>
              <option value="slideDown">Slide Down</option>
            </select>
          </div>
        </div>
      </div>
    }
  </div>
  <div class="image-component-list file-component-list te-scrollable-container"
    [ngClass]="{'active-duration' : selectedImages.length > 1 && !isUploading, 'active-help-text': helpText}"
    rvSpinner="image-file-loader" [rvShowSpinner]="templateEditorService.loadingPresentation || stockLibraryService.loading">
    <div (rvSortable)="sortItem($event)" appendTo=".component-container" class="sortable-list">
      @for (image of selectedImages; track image) {
        <template-editor-file-entry
          class="rv-sortable-item"
          [shown]="selectedImages.length > 0 && !isUploading"
          [entry]="image"
          fileType="image"
          (removeEvent)="removeImageFromList($event)"
          [showGripHandle]="selectedImages.length > 1">
        </template-editor-file-entry>
      }
    </div>
    <template-editor-empty-file-list fileType="image" [isEditingLogo]="isEditingLogo()"
      [hidden]="isUploading || selectedImages.length !== 0 || templateEditorService.loadingPresentation">
    </template-editor-empty-file-list>
    <basic-uploader uploaderId="image-list-uploader"
      [uploadManager]="uploadManager"
      validType="image"
      [fileList]="fileUploadList"
      [shown]="isUploading">
    </basic-uploader>
  </div>
  <div class="file-component-list-action-button-bar">
    <div class="pt-4 border-top">
      <button id="upload-images-unsubscribed" class="btn btn-default btn-block" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
        @if (!isEditingLogo()) {
          <strong>Upload Images</strong>
        }
        @else {
          <strong>Upload A Logo</strong>
        }
      </button>
      <label id="image-list-uploader-label"
        class="btn btn-primary btn-block aligner"
        for="image-list-uploader"
        [attr.disabled]="isUploading ? true : null" [shown]="currentPlanService.isPlanActive()"
      >
        @if (!isEditingLogo()) {
          <strong>Upload Images</strong>
        }
        @else {
          <strong>Upload A Logo</strong>
        }
      </label>
    </div>
    <div class="mt-3">
      <button id="image-list-storage-button" class="btn btn-default btn-block" (click)="selectFromStorage()" [disabled]="isUploading">
        <strong>Select from Storage</strong>
      </button>
    </div>
    <div class="mt-3" *requireRole="'sa'">
      <button
        id="stock-image-library"
        class="btn btn-default btn-block"
        (click)="stockLibraryService.showStockLibrary('image', boundAddFile)"
        [disabled]="isUploading"
      >
        <strong>Select from Stock Image Library</strong>
      </button>
    </div>
    <div id="edit-in-canva" class="mt-3">
      <button id="canva-unsubscribed" class="btn btn-default btn-block" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
        <strong>Design with Canva</strong>
      </button>
      <canva-button (designPublished)="onDesignPublished($event)" [disabled]="isUploading" [shown]="currentPlanService.isPlanActive()"></canva-button>
    </div>
  </div>
</div>
