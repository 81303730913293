<div (rvSortable)="sortItem($event)" class="scrollable-list sortable-list border-container">
  @for (playlistItem of playlistItems; track playlistItem; let i = $index) {
    <div class="list-group-item rv-sortable-item flex-row">
      <div class="row-entry">
        <div class="rv-sortable-handle">
          <streamline-icon class="streamline-component-icon" name="sortableHandle" width="14" height="14"></streamline-icon>
        </div>
        <div (click)="manage(playlistItem)" class="playlist-item-name mr-auto u_clickable" [ngClass]="{'not-apple-tv-compatible': !isAppleTVCompatible(playlistItem)}">
          @if (playlistItem.type !== 'presentation') {
            <p class="madero-link u_remove-bottom" id="playlistItemNameCell"><strong>{{playlistItem.name}}</strong></p>
          }
          @else {
            <p class="madero-link u_remove-bottom" id="presentationNameCell"><strong [presentationName]="playlistItem.objectReference">Presentation Item</strong></p>
          }
          <p class="text-sm text-gray u_remove-bottom">
            <span class="text-gray" [shown]="playlistService.hasInsecureUrl(playlistItem)"><strong class="text-danger">Insecure URL</strong> • </span>
            <span [innerHTML]="timelineDescription.updateLabel(playlistItem)"></span> • {{ playlistItem.playUntilDone ? 'Play Until Done' : playlistItem.duration + 's' }} • {{ playlistService.getItemTransition(playlistItem) }}
          </p>
        </div>
        @if (!isAppleTVCompatible(playlistItem)) {
          <span class="mr-3 mt-2" matTooltip="Not Apple TV Compatible" matTooltipPosition="below" runZone>
            <img src="../images/apple-tv-incompatible-icon.svg" alt="Not Apple TV Compatible" width="32" height="23">
          </span>
        }
        <button type="button" class="btn-icon p-0" id="duplicateButton" (click)="playlistService.duplicatePlaylistItem(playlistItem)">
          <streamline-icon class="streamline-component-icon mr-3" name="copy" width="16" height="18"></streamline-icon>
        </button>
        <button type="button" class="btn-icon p-0" id="removeButton" (click)="remove(playlistItem)">
          <streamline-icon class="streamline-component-icon m-0" name="trash" width="16" height="18"></streamline-icon>
        </button>
      </div>
    </div>
  }
</div>
