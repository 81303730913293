<div class="btn-group">
  <button id="gridViewSelector"
    class="btn"
    [ngClass]="{'btn-toggle-blue-off': !isListView, 'btn-toggle-blue-on': isListView}"
    (click)="toggleListView()"
    title="List View">
    <img src="/images/icon-list{{!isListView ? '' : '-inv'}}.svg" />
  </button>
  <button id="listViewSelector"
    class="btn"
    [ngClass]="{'btn-toggle-blue-off': isListView, 'btn-toggle-blue-on': !isListView}"
    (click)="toggleListView()"
    title="Grid View">
    <img src="/images/icon-module{{isListView ? '' : '-inv'}}.svg" />
  </button>
</div>