<!-- Common Header Plan Info -->
<div id="plan-banner" class="hidden-xs">
  @if (getVisibleBanner() === 'parent' || getVisibleBanner() === 'parentPurchased') {
    <div id="parent-plan-banner" class="alert p-2 mb-0 bg-success text-right">
      <div class="u_margin-right">
        <span class="ml-2">Subscription managed by {{plan.parentPlanCompanyName || "the Parent Company"}}</span>
      </div>
    </div>
  }
  @else if (getVisibleBanner() === 'free') {
    <div id="free-plan-banner" class="alert alert-plan plan-active text-right">
      <div class="u_margin-right">
        <span class="font-weight-bold">Get more out of Rise Vision!</span>
        <a class="u_margin-left" href="#" (click)="showPlans()">See Our Plans</a>
      </div>
    </div>
  }
  @else if (getVisibleBanner() === 'cancelled') {
    <div id="cancelled-plan-banner" class="alert alert-plan plan-active text-right">
      <div class="u_margin-right">
        <span class="font-weight-bold">Your subscription expires on {{ plan.currentPeriodEndDate | date : 'd-MMM-yyyy'}}</span>
        <a class="ml-3 btn btn-xs btn-primary" uiSref="apps.billing.home">Account Settings</a>
      </div>
    </div>
  }
  @else if (getVisibleBanner() === 'trial') {
    <div id="trial-plan-banner" class="alert alert-plan plan-active text-right">
      <div class="u_margin-right">
        <span class="font-weight-bold">You have {{plan.trialPeriod}} days left on your trial!</span>
        <a [shown]="!isEnterpriseSubCompany()" class="ml-3 btn btn-xs btn-primary" href="#" (click)="showPlans()">Subscribe Now</a>
      </div>
    </div>
  }
  @else if (getVisibleBanner() === 'expired') {
    <div class="alert alert-plan plan-suspended text-center">
      <div class="u_margin-right">
        <span class="font-weight-bold" [shown]="!plan.playerProTotalLicenseCount">Your trial has expired! You are now on the Free Plan. Your Displays are no longer Licensed.</span>
        <span class="font-weight-bold" [shown]="plan.playerProTotalLicenseCount">Your trial has expired! You are now on the Free Plan. Some of your Displays are no longer Licensed.</span>
        <a [shown]="!isEnterpriseSubCompany()" class="ml-3 btn btn-xs btn-primary" href="#" (click)="showPlans()">Subscribe Now</a>
      </div>
    </div>
  }
  @else if (getVisibleBanner() === 'suspended') {
    <div class="alert alert-plan plan-suspended text-center">
      <div class="u_margin-right">
        <span class="font-weight-bold">There was an issue processing your payment. Please update your billing information. Your Displays may be affected.</span>
        <a class="ml-3 btn btn-xs btn-primary" uiSref="apps.billing.home">Update Billing</a>
      </div>
    </div>
  }
</div>
