<div class="pl-0 file-component-list-file-row">
  @if (showGripHandle) {
    <div class="rv-sortable-handle">
      <streamline-icon class="streamline-component-icon" name="sortableHandle" width="14" height="14"></streamline-icon>
    </div>
  }
  <div class="file-thumbnail">
    <div class="file-helper"></div>
    @if (entry.exists && !isStreamlineThumbnail()) {
      <img
        [src]="getThumbnailUrl()"
        [ngClass]="{ 'img-contain': isDefaultThumbnail() }">
    }
    <div class="broken-link" [hidden]="entry.exists && !isStreamlineThumbnail()">
      <streamline-icon width="30" height="30"
        name="{{ entry.exists ? getStreamlineIcon() : 'forbidden' }}"
      ></streamline-icon>
    </div>
  </div>
  <div class="file-entry" [ngClass]="{'is-error' : !entry.exists}">
    <div class="file-text">
      <div class="file-name" title="{{ getStoragePath() }}">{{getFileName()}}</div>
      <div class="file-error" [hidden]="entry.exists">
        Your {{fileType}} has been deleted, renamed, or moved.
      </div>
    </div>
    <div class="file-remove">
      <a href="#" (click)="removeFileFromList()">
        <streamline-icon class="ml-3 streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
      </a>
    </div>
  </div>
</div>
