<div class="flex-row">

  <input type="text" class="input-stretchy" (keydown)="inputKeyDown($event)" name="stretchyInput"
    [(ngModel)]="ngModel" (ngModelChange)="ngModelChange.emit(ngModel)" (blur)="onInputBlur()" [disabled]="!isEditingInput">
  <div>
    <button id="editButton" [disabled]="isEditingInput" type="button" class="btn-icon ml-3" (click)="setEditable()">
      <streamline-icon class="streamline-component-icon" name="pencil" width="16" height="16"></streamline-icon>
    </button>
  </div>
</div>
