import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserStateService } from 'src/app/auth/services/user-state.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {
  public static readonly ENDPOINT_REVOKE = 'revoke';
  public static readonly ENDPOINT_USERNAME = 'username';

  constructor(
    protected httpClient: HttpClient,
    private userStateService: UserStateService
  ) { }

  _getRequestOptions(): any {
    var authorization = (this.userStateService.getAccessToken().token_type === 'Bearer') ?
      this.userStateService.getAccessToken().token_type + ' ' + this.userStateService.getAccessToken().access_token :
      this.userStateService.getAccessToken().access_token;

    var headers = new HttpHeaders({
      'authorization': authorization
    });

    var requestOptions = {
      headers,
      withCredentials: true,
      responseType: 'json'
    };

    return requestOptions;
  }

  revoke(account) {
    const request = new HttpRequest( 'GET',
      environment.GOOGLE_SERVICE_URL + GoogleService.ENDPOINT_REVOKE, {
        withCredentials: true,
        responseType: 'json',
        params: new HttpParams({fromObject:{
          account: account
        }})
      }
    );

    return this.httpClient.request(request)
      .toPromise()
      .then((response: any) => {
        if (response && response.body && response.body.revoked) {
          return response.body.revoked;
        } else {
          throw new Error('Failed to revoke account');
        }
      }).catch( (err) => {
        console.error('Failed to revoke access for user.', err);

        throw err;
      });
  }

  getUsername(account) {
    const body = {account};
    const request = new HttpRequest('POST',
      environment.GOOGLE_SERVICE_URL + GoogleService.ENDPOINT_USERNAME,
      body,
      this._getRequestOptions()
    );

    return this.httpClient.request(request)
    .toPromise()
    .then((response: any) => {
        if (response.body) {
          return {username: response.body.username};
        } else {
          throw new Error('Failed to get username');
        }
      })
    .catch( (err) => {
      console.error('Failed to get username.', err);
      throw err;
    });
  }

}
