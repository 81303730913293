import { Component, Input, Output, EventEmitter } from '@angular/core';
import { StorageUtilsService } from '../../services/storage-utils.service';

import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'storage-selector',
  templateUrl: './storage-selector.component.html',
  styleUrls: ['./storage-selector.component.scss']
})
export class StorageSelectorComponent {

  @Input() type: string;
  @Input() label: string = '';
  @Input() selected: boolean = false;
  @Output() picked: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor (
    private storageUtilsService: StorageUtilsService
  ) {}

  open () {
    this.storageUtilsService.showStorageModal(this.type).then((files) => {
      this.picked.emit(this.storageUtilsService.getFileUrls(files));
    });
  }

}

angular.module('risevision.storage.directives')
  .directive(
    'storageSelector',
    downgradeComponent({
      component: StorageSelectorComponent
    }) as angular.IDirectiveFactory
  );
