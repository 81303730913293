import { Component, NgZone } from '@angular/core';

import { ScrollingListService } from 'src/app/ajs-upgraded-providers';
import { EditorService } from '../../services/editor.service';
import { PresentationUtilsService } from '../../services/presentation-utils.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { CompanyIdModalComponent } from 'src/app/components/scrolling-list/company-id-modal/company-id-modal.component';
import { TemplateEditorService } from 'src/app/template-editor/services/template-editor.service';
import { UsernamePipe } from 'src/app/shared/pipes/username.pipe';
import { PresentationApiService } from 'src/app/api/services/presentation-api.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'presentation-list',
  templateUrl: './presentation-list.component.html',
  styleUrls: ['./presentation-list.component.scss']
})
export class PresentationListComponent {
  search: any = {
    sortBy: 'changeDate',
    reverse: true,
    name: 'Presentations'
  };

  listOperations = {
    name: 'Presentation',
    operations: [{
      name: 'Copy',
      beforeBatchAction: (selected) => {
        const plural = selected.length > 1 ? 's' : '';
        return this.modalService.showModal(CompanyIdModalComponent, {
          initialState: {
            title: 'Copy Selected Presentation' + plural,
            description: 'A copy of ' + (plural ? 'each of ' : '') + 'the selected presentation' + plural +
              ' will be added to the Company ID specified below.'
          }
        });
      },
      actionCall: (presentation?, companyId?) => {
        return this.editorService.copyPresentationToCompany(presentation, companyId).then(() => {
          if (presentation.companyId === companyId) {
            this.presentations.doSearch();
          }
        });
      },
      requireRole: 'sa'
    },
    {
      name: 'Delete',
      actionCall: (presentation?) => {
        return this.editorService.deletePresentationByObject(presentation, true);
      },
      showActionError: (err?) => {
        if (err && err.status === 409) {
          return true;
        }
        return false;
      },
      requireRole: 'cp ap'
    }]
  };
  presentations = this.ScrollingListService(this.presentationApiService.list.bind(this.presentationApiService), this.search, this.listOperations);

  isHtmlPresentation = this.presentationUtils.isHtmlPresentation;

  filterConfig = {
    placeholder: 'Search Presentations',
    id: 'presentationSearchInput'
  };

  assignedToUsers = '';

  constructor(private ScrollingListService: ScrollingListService,
    private presentationApiService: PresentationApiService,
    public editorService: EditorService,
    public templateEditorFactory: TemplateEditorService,
    private presentationUtils: PresentationUtilsService,
    private modalService: ModalService,
    public companyStateService: CompanyStateService,
    private usernamePipe: UsernamePipe,
    private ngZone: NgZone) {

    }

    popoverText(assignedUsers: string[]) {
      if (assignedUsers.length > 1) {
        this.assignedToUsers = assignedUsers
          .slice(1)
          .map(user => user ? this.usernamePipe.transform(user) : '')
          .join('<br>');
      } else {
        this.assignedToUsers = '';
      }

      setTimeout(() => {
        this.ngZone.run(() => {});
      });
    }

}
