<div class="form-group mb-0">
  <div class="flex-row">
    <div class="row-entry">
      <label class="control-label distribution-label pull-left mb-0 u_margin-right">Distribution:</label>

      <mat-checkbox [shown]="!readonly" class="mr-1" id="allDisplaysCheckbox" (change)="outputDistributeToAll()" [(ngModel)]="distributeToAll"></mat-checkbox>
			<span [shown]="!readonly || distributeToAll" class="mr-3 u_clickable" (click)="distributeToAll = !distributeToAll; outputDistributeToAll()">Show on all displays.</span>
      <span [shown]="readonly && !distributeToAll">{{ distributionSelectionMessage }}</span>
    </div>
  </div>
  <span id="distributionFieldMadero" class="madero-link u_clickable" timeline="timeline" (click)="manage()" [shown]="!readonly && !distributeToAll">

    <span id="distributionFieldTextMadero">{{ distributionSelectionMessage }}</span>
  </span>
</div>
