import { Injectable } from '@angular/core';
import { MediaSelectorService } from './media-selector.service';
import { cloneDeep } from 'lodash';
import { BasePlaylistService, BasePlaylistTemplateItem } from './base-playlist.service';

export type PlaylistTemplateItem = BasePlaylistTemplateItem & {
  productCode?: string;
  duration: number|string;
  'play-until-done': boolean;
  'transition-type': string;
  attributes: { [key: string]: string|number|boolean };
  'help-text'?: string;
  fileMissing?: boolean;
  timeline?: any;
  editing?: boolean;
  revisionStatusName?: string;
  removed?: boolean;
  name?: string;
};

@Injectable({
  providedIn: 'root'
})
export class PlaylistService extends BasePlaylistService {

  playlistItems: PlaylistTemplateItem[] = [];

  private _mapItemToEditorFormat(item: any): PlaylistTemplateItem {
    return {
      'duration': item.duration,
      'timeline': item.timeline? this.safeParseJson(item.timeline) : null,
      'play-until-done': item['play-until-done'] === '' ? true : item['play-until-done'],
      'transition-type': item['transition-type'],
      'tagName': item.element && item.element.tagName ? item.element.tagName : undefined,
      'label': item.element && item.element.label,
      'help-text': item.element && item.element['help-text'],
      'id': item.element && item.element.attributes ? item.element.attributes['presentation-id'] : undefined,
      'productCode': item.element && item.element.attributes ? item.element.attributes['template-id'] : undefined,
      'attributes': item.element.attributes || {}
    };
  }

  private _mapEditorToItemFormat(item) {
    var updatedItem = {
      'duration': item.duration,
      'timeline': item.timeline? JSON.stringify(item.timeline) : null,
      'play-until-done': item['play-until-done'],
      'transition-type': item['transition-type'],
      'element': {
        'tagName': item.tagName || 'rise-embedded-template',
        'label': item.label,
        'help-text': item['help-text'],
        'attributes': item.attributes || {}
      }
    };
    updatedItem.element.attributes['template-id'] = item.productCode;
    updatedItem.element.attributes['presentation-id'] = item.id;

    return updatedItem;
  }

  private _processItemToAdd(type, item) {
    this.playlistItems.push(item);
    this.save();

    this.analyticsFactory.track('Playlist Item Added', {
      componentType: type,
    });

    this.editComponent(item);
  }

  showAddTemplates() {
    this.componentsService.editComponent({
      type: 'rise-presentation-selector'
    });

    this.analyticsFactory.track('Playlist Item Added', {
      componentType: 'rise-embedded-template',
    });
  }

  showMediaSelector(options?: any) {
    super.showMediaSelector(options);

    this.analyticsFactory.track('Playlist Item Added', {
      componentType: MediaSelectorService.FOLDER_COMPONENT,
      companyId: this.companyStateService.getSelectedCompanyId(),
      presentationId: this.templateEditorService.presentation.id,
      presentationName: this.templateEditorService.presentation.name
    });
  }

  showCanva(): void {
    super.showCanva();

    this.analyticsFactory.track('Playlist Item Added', {
      componentType: MediaSelectorService.FOLDER_COMPONENT, // TODO: revisit this value when a tracking implementation is done for user components to include sub type, i.e. rise-folder/canva
      companyId: this.companyStateService.getSelectedCompanyId(),
      presentationId: this.templateEditorService.presentation.id,
      presentationName: this.templateEditorService.presentation.name
    });
  }

  jsonToPlaylistItems(playlistItems): PlaylistTemplateItem[] {
    let result = [];

    if (Array.isArray(playlistItems)) {
      result = playlistItems.map(this._mapItemToEditorFormat.bind(this));
    }

    return result;
  }

  playlistItemsToJson(): any {
    return this.playlistItems.map(this._mapEditorToItemFormat);
  }

  save(): void {
    var itemsJson = this.playlistItemsToJson();
    this.attributeDataService.setAttributeData(this.componentId, 'items', itemsJson);
  }

  editComponent(item: BasePlaylistTemplateItem) {
    super.editComponent(item, true);
  }

  addItem(type: string, options?: any): void {
    if (type === 'rise-folder') {
      if (options && options.fileType && options.fileType === PlaylistService.FOLDER_TYPE.GOOGLE_PHOTOS_ALBUM) {
        return this.showGooglePhotos();
      }

      return this.showMediaSelector(options);
    }

    if (type === 'rise-stock-library') {
      return this.showMediaSelector({
        stockLibrary: true
      });
    }

    if (type === 'rise-canva') {
      this.showCanva();
      return;
    }

    if (type === 'rise-presentation-selector') {
      this.showAddTemplates();
      return;
    }

    const component = this.getComponentByType(type);
    const item = {
      'duration': 10,
      'play-until-done': !!component.playUntilDone,
      'transition-type': 'normal',
      'tagName': type,
      'attributes': cloneDeep(component.defaultAttributes) || {}
    };

    this._processItemToAdd(type, item);
  }

  showGooglePhotos() {
    this._processItemToAdd('rise-folder', {
      'duration': 10,
      'play-until-done': true,
      'transition-type': 'normal',
      'tagName': 'rise-folder',
      'attributes': cloneDeep(this.getGooglePhotosItemAttributes())
    });
  }
}
