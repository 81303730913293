<div class="attribute-editor-component" rvSpinner="calendar-editor-loader" [rvShowSpinner]="spinner">
  <div>
    <div class="attribute-editor-row" [shown]="!userAccount">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label>Account:</label><br>
            <div class="mb-4" [shown]="componentAccount">Google Calendar is connected to someone else's account</div>
            <div class="text-sm">
              Connect to Google Calendar to access your calendars
            </div>
            <button class="btn btn-google-auth btn-hg btn-block mt-2 folder-component-photos" (click)="confirmConnect()">
              <img src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg">
              <div class="mx-auto">Sign in with Google</div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="attribute-editor-row" [shown]="revokeFailed">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <div class="text-sm">
              We could not revoke your access with Google at this time. We strongly recommend you manually revoke access in your Google account.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [shown]="userAccount">
      <div class="attribute-editor-row">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label>Account:</label><br>
              {{ sameAccount ? "Google Calendar is connected to your account" : "Google Calendar is connected to someone else's account" }}<br>
              <button class="btn btn-default btn-block mt-2 folder-component-photos" [shown]="sameAccount" (click)="confirmDisconnect()">Disconnect from Google Calendar</button>
              <button class="btn btn-default btn-block mt-3 folder-component-photos" [shown]="!sameAccount" (click)="confirmChange()">Change Google Calendar Account</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="attribute-editor-row">
      <div class="form-group has-feedback">
        <label class="control-label" for="te-calendar-id">Calendar:</label>
        <select class="form-control" id="te-calendar-id" name="te-calendar-id" [(ngModel)]="selectedCalendar" (ngModelChange)="selectedCalendarChanged()">
          <option value="">Public URL</option>
          @for (c of calendars; track c) {
            <option [value]="c.id">{{c.name}}</option>
          }
        </select>
      </div>
      @if (selectedCalendar && this.sameAccount) {
        <div>
          <a [href]="calendarLink()" target="_blank">View Calendar</a>
        </div>
      }
    </div>
  </div>
  <div class="attribute-editor-row" [hidden]="selectedCalendar">
    <div class="form-group has-feedback" [ngClass]="{ 'has-error': sourceResult && sourceResult !== 'VALID', 'has-success': sourceResult === 'VALID' && source !== '' }">
      <label class="control-label" for="te-calendar-source">Calendar URL:</label>
      <input type="text" id="te-calendar-source" class="form-control" name="te-calendar-source" [(ngModel)]="source" placeholder="Calendar URL or ID" (debounceEvent)="sourceChanged()">
      <streamline-icon class="overlay-icon form-control-feedback" aria-hidden="true" name="{{sourceResult && sourceResult !== 'VALID' ? 'exclamation' : 'checkmark'}}" width="14" height="12"></streamline-icon>
      <p class="help-block">
        @switch (sourceResult) {
          @case ('INVALID') {
            <span>
              The calendar is not valid or is not publicly accessible. Refer to <a target="_blank" href="https://help.risevision.com/hc/en-us/articles/8357738823188-How-do-I-show-a-Google-Calendar-on-my-display-">this article</a> for instructions on how to correct this error.
            </span>
          }
          @case ('FAILED') {
            <span>Could not retrieve information for calendar {{source}}. Please try again. If you need help email <a target="_blank" href="mailto:support@risevision.com">support&#64;risevision.com</a></span>
          }
        }
      </p>
      @if (source && sourceResult === 'VALID') {
        <div>
          <a [href]="calendarLink()" target="_blank">View Calendar</a>
        </div>
      }
    </div>
  </div>
  <div class="attribute-editor-row">
    <label class="control-label">Date range:</label>
    <div class="has-feedback" [ngClass]="{ 'has-error': !isValidRange(), 'has-success': isValidRange() && range }">
      <div class="flex-row">
        <input type="number" class="form-control mr-2" id="te-calendar-range" name="te-calendar-range" placeholder="Range"
          [(ngModel)]="range"
          [min]="rangeMin"
          [max]="rangeMax"
          (ngModelChange)="rangeChanged()">
        <select class="form-control" id="te-calendar-period" name="te-calendar-period" [(ngModel)]="period" (ngModelChange)="periodChanged()">
          <option value="days">Days</option>
          <option value="weeks">Weeks</option>
          <option value="months">Months</option>
        </select>
      </div>
      @if (!isValidRange()) {
        <p class="help-block">
          <span>Please provide a number between {{rangeMin}} and {{rangeMax}}.</span>
        </p>
      }
    </div>
  </div>
  <div class="attribute-editor-row">
    <span class="flex-row">
      <madero-checkbox id="te-calendar-completed" name="te-calendar-completed" ngDefaultControl [(ngModel)]="completed" (ngModelChange)="completedChanged()"></madero-checkbox>
      <label for="te-calendar-completed">Hide Today's Completed Events</label>
    </span>
  </div>
</div>
