import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { AuthPopup } from './auth-popup';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Injectable({
  providedIn: 'root'
})

export class OAuthService {

  constructor(
    private httpClient: HttpClient,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService
  ) { }

  _getRequestOptions(): any {
    var authorization = (this.userStateService.getAccessToken().token_type === 'Bearer') ?
      this.userStateService.getAccessToken().token_type + ' ' + this.userStateService.getAccessToken().access_token :
      this.userStateService.getAccessToken().access_token;

    var headers = new HttpHeaders({
      'authorization': authorization
    });

    var requestOptions = {
      headers,
      withCredentials: true,
      responseType: 'json'
    };

    return requestOptions;
  }

  _getBody(provider, account, extend?: {}): any {
    var body: any = {provider};

    if (this._isUserAccount(provider)) {
      body.account = account || this.userStateService.getUserHash();
    } else {
      body.companyId = this.companyStateService.getSelectedCompanyId();
    }

    return Object.assign({}, extend, body);
  }

  _isUserAccount(provider) {
    return provider === 'twitter' ? false : true;
  }

  _getStatus(provider, account, scope) {
    const request = new HttpRequest('POST',
      environment.OAUTH_TOKEN_PROVIDER_URL + 'status',
      this._getBody(provider, account, scope ? {scope} : null),
      this._getRequestOptions()
    );

    return this.httpClient.request(request)
      .toPromise()
      .catch((error) => {
        console.debug('Could not get Status! ', error);

        throw error;
      });
  }

  getConnectionStatus(provider, scope = null) {

    return this._getStatus(provider, null, scope)
      .then((response: any) => {
        if (response.body && Array.isArray(response.body.authenticated) && response.body.authenticated
          .length) {
          return {...response.body, ...{authenticated: true}};
        } else {
          throw new Error('No authenticated on the response');
        }
      });
  }

  getUsername(provider, account) {

    //account might be missing if component is new i.e. not yet configured
    if (!account) {
      return Promise.resolve({authenticated: null, username: null});
    }

    return this._getStatus(provider, account, "")
      .then((response: any) => {
        if (response.body) {
          return {
            authenticated: Array.isArray(response.body.authenticated) && response.body.authenticated.length > 0,
            username: response.body.username
          };
        } else {
          throw new Error('Invalid response');
        }
      });
  }

  getAuthPopupInstance() {
    return new AuthPopup();
  }

  authenticate(provider, scope = null) {

    const id = this.getUserIdentifier(provider);

    let  authPopup = this.getAuthPopupInstance();

    return authPopup.popup(provider, id, scope)
      .then((result: any) => {
        if (result && result.key) {
          return {key: result.key, username: result.username};
        } else {
          throw new Error('No Key');
        }
      })
      .catch((error) => {
        console.debug('Could not authenticate with OAuth Token Provider! ', error);

        throw error;
      });
  }

  revoke(provider) {
    const request = new HttpRequest('POST',
      environment.OAUTH_TOKEN_PROVIDER_URL + 'revoke',
      this._getBody(provider, null),
      this._getRequestOptions()
    );

    return this.httpClient.request(request)
      .toPromise()
      .then((response: any) => {
        if (response && response.body && response.body.revoked) {
          return response.body.revoked;
        } else {
          throw new Error('Failed to revoke account');
        }
      })
      .catch((error) => {
        console.debug('Revoke failed', error);

        throw error;
      });
  }

  getUserIdentifier(provider) {
    return this._isUserAccount(provider) ? this.userStateService.getUserHash() : this.companyStateService.getSelectedCompanyId();
  }

}
