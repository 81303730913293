<!-- Presentation Name -->
<stretchy-input class="presentation-name" [(ngModel)]="templateEditorFactory.presentation.name" ngDefaultControl></stretchy-input>
<div>
  <button id="deleteButton" [disabled]="!templateEditorFactory.presentation.id" (click)="confirmDelete()" class="btn-icon ml-2">
    <streamline-icon class="streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
  </button>
  <div class="btn-group" dropdown>
    <button id="moreSettingsButton" dropdownToggle class="btn btn-default-icon dropdown-toggle ml-2">
      <streamline-icon name="ellipsis" width="20" height="20"></streamline-icon>
    </button>
    <ul class="dropdown-menu dropdown-xs-bump-left" *dropdownMenu role="menu">
      <li><a id="changeTemplateButton" uiSref="apps.editor.add">New Presentation</a></li>
      <li><a id="copyPresentationButton" class="u_clickable" [attr.disabled]="!templateEditorFactory.presentation.id" (click)="templateEditorFactory.copyPresentation()">Duplicate</a></li>
      <li *requireRole="'!ap'"><a id="sharePresentationButton" class="u_clickable" [attr.disabled]="!templateEditorFactory.presentation.id" (click)="openSettings()">Save Presentation As Template</a></li>
    </ul>
  </div>
</div>
<div class="flex-row ml-auto hidden-xs">
  <div class="auto-save pr-4">
    <last-modified [changeDate]="templateEditorFactory.presentation.changeDate" [changedBy]="templateEditorFactory.presentation.changedBy"></last-modified>
    •
    @if (templateEditorFactory.hasContentEditorRole()) {
      <span id="autoSavingDesktop">
        <span [shown]="!templateEditorFactory.savingPresentation && templateEditorFactory.isUnsaved()">
          Unsaved changes
        </span>
        <span [shown]="templateEditorFactory.savingPresentation">
          Saving changes...
        </span>
        <span [shown]="!templateEditorFactory.savingPresentation && !templateEditorFactory.isUnsaved()">
          All changes saved <streamline-icon name="checkmark" width="12" height="12"></streamline-icon>
        </span>
      </span>
    }
    @else {
      <span class="text-danger">
        You don’t have permission to edit
      </span>
    }
  </div>
  <button id="publishButtonDesktop" *requireRole="'cp ap'" class="btn btn-primary btn-fixed-width"
    [disabled]="templateEditorFactory.isPublishDisabled()"
    (click)="templateEditorFactory.publish()">
    Publish
  </button>
</div>
