import { Component, ElementRef } from '@angular/core';
import { FileDownloader } from 'src/app/ajs-upgraded-providers';
import { ComponentsService } from '../../services/components.service';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';
import { CanvaApiService } from '../../services/canva-api.service';
import { MediaSelectorService } from '../services/media-selector.service';

@Component({
  selector: 'component-canva',
  templateUrl: './canva.component.html',
  styleUrls: ['./canva.component.scss']
})

export class CanvaComponent {
  fileUploaderId = 'canva-file-uploader';
  fileType: string = 'image';

  storageUploadManager;
  isUploading;
  canvaUploadList;

  constructor(private elementRef: ElementRef,
    private componentsService: ComponentsService,
    private fileDownloader: FileDownloader,
    private canvaApi: CanvaApiService,
    private mediaSelectorService: MediaSelectorService,
    public currentPlanService: CurrentPlanService,
  ) {
    componentsService.registerDirective({
      type: 'rise-canva',
      element: this.elementRef.nativeElement,
      show: () => {
        this._init();
      }
    });

    this.storageUploadManager = {
      uploadedFiles: [],
      folderPath: '',
      onUploadStatus: (isUploading) => {
        const wasUploading = this.isUploading;
        this.isUploading = isUploading;

        if (wasUploading && !isUploading) {
          this.componentsService.showPreviousPage();

          this.mediaSelectorService.storageFilesSelected(this.storageUploadManager.uploadedFiles, this.fileType);

          this.storageUploadManager.uploadedFiles = [];
        }
      },
      addFile: (file) => {
        this.storageUploadManager.uploadedFiles.push(file);
      }
    };
  }

  _init(): void {
    this.canvaApi.createDesign().then((options) => {
      this._onDesignPublished(options);
    }).catch(()=>{
      // Canva was likely closed - prevents Unhandled Promise rejection
      this.componentsService.showPreviousPage();
    });
  }

  _onDesignPublished(options) {
    console.info('Canva result:', options);
    var filepath = StorageManagerService.CANVA_FOLDER;
    filepath += options.designTitle? options.designTitle + '_' : '';
    filepath += options.designId + '.png';
    this.fileDownloader(options.exportUrl, filepath)
      .then((file) => {
        this.canvaUploadList = [file];
      })
      .catch((err) => {
        console.error('Could not import Canva design.', err);
      });
  }
}
